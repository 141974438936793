<template>
  <div :id="isZoom ? 'zoomed-manhattan-plot' : 'manhattan-plot'"></div>
</template>

<script>
import * as echarts from "echarts/dist/echarts.js";
import "echarts/lib/chart/scatter";
import axios from "axios";
import _ from "lodash";

export default {
  name: "ManhattanPlot",

  props: {
    fileName: {
      type: String,
      required: true,
    },
    isZoom: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      plotOptions: {},
    };
  },

  mounted: function() {
    axios
      .get(window.location.origin + "/manhattan/" + this.fileName)
      .then((response) => {
        const plotData = response.data;

        let limitsIdx = 0;
        const lastLimitsIdx = plotData.limits.length;
        let lastTick = 0;

        this.plotOptions = {
          toolbox: {
            show: this.isZoom ? true : false,
            feature: {
              dataZoom: {},
              saveAsImage: {},
            },
          },
          title: {
            text: plotData.title,
            textStyle: { fontSize: 16 },
            left: "center",
            top: 0,
          },
          tooltip: {
            showDelay: 0,
            formatter: function(params) {
              if (params.componentType == "markLine") {
                const text =
                  params.data.name + " threshold: " + params.data.value;
                return text;
              } else {
                let text = params.data.of_interest
                  ? "<strong>Of interest</strong><br>"
                  : "";
                text += params.data.text;
                return text;
              }
            },
          },
          grid: {
            show: false,
          },
          xAxis: {
            name: "Chromosome",
            nameLocation: "center",
            nameTextStyle: { padding: [8, 4, 5, 6] },
            axisLabel: {
              formatter: function(v) {
                // start from the beginning again
                if (v < lastTick) {
                  lastTick = 0;
                  limitsIdx = 0;
                }

                if (limitsIdx >= lastLimitsIdx) return null;
                const currentLimits = plotData.limits[limitsIdx];

                if (v >= currentLimits[2]) {
                  limitsIdx++;
                  lastTick = currentLimits[3];
                  return currentLimits[3];
                }
              },
            },
            axisTick: {
              show: false,
            },
            splitNumber: 100,
            splitLine: {
              show: false,
            },
            type: "value",
          },
          yAxis: {
            min: 1,
            name: "-log10(p)",
            position: "left",
            nameRotate: 90,
            nameLocation: "center",
            nameTextStyle: { padding: [8, 4, 5, 6] },
          },
          dataset: _.map(plotData.data, (d) => {
            return {
              source: d.data,
              dimensions: ["x", "y", "text"],
            };
          }),
          series: _.map(plotData.data, (d, i) => {
            let color = d.color ? "#5470c6" : "#73c0de";
            color = d.name == "of interest" ? "#fc8452" : color;

            let markLine;
            if (!i) {
              markLine = {
                lineStyle: {
                  type: "dashed",
                  color: "#3ba272",
                },
                label: { show: false },
                symbol: ["none", "none"],
                data: [
                  { yAxis: plotData.thresholds[0], name: "-log10(p)" },
                  { yAxis: plotData.thresholds[1], name: "-log10(p)" },
                ],
              };
            }

            return {
              symbolSize: 5,
              datasetIndex: i,
              color: color,
              type: "scatter",
              large: true,
              largeThreshold: 50,
              itemStyle: {
                opacity: 0.5,
              },
              markLine: markLine,
            };
          }),
        };

        const elemId = this.isZoom ? "zoomed-manhattan-plot" : "manhattan-plot";
        let manhattanPlot = echarts.init(document.getElementById(elemId));
        manhattanPlot.setOption(this.plotOptions);
        manhattanPlot.resize();
      });
  },
};
</script>

<style lang="scss" scoped>
#manhattan-plot {
  height: 350px;
}
#zoomed-manhattan-plot {
  height: 560px;
}
</style>

<template>
  <v-card>
    <v-card-title class="text-h5">
      Pipeline 2 description
    </v-card-title>

    <v-card-text class="text-left">
      <h2>
        Top eQTL data from Monoculture and Coculture codnitions based on the CPM
        values
      </h2>
      <br />
      <h3>
        Gene expression values were converted to CPM (corrected by sva + trimmed
        mean of M values (TMM) normalization)
      </h3>
      <p>
        Here we further refine our results by making some key modifications to
        the method that was used in the earlier section. The Pipeline and the
        steps that were modified are shown below. Currently, we display the Top
        Cis-eQTLs for Candida albicans from Monoculture (Isolates grown in
        Normal Tissue Culture medium) and Coculture (TR146 cells infected for
        6hrs with Isolates in Normal Tissue Culture medium) condition. We will
        soon update the site with the results for eQTLs from Monoculture and
        Coculture conditions based on TPM values.
      </p>
      <p>
        The Data tables were generated using ‘Kable’ or ‘DT’ packages in R. Each
        eQTL in the data table contains clickable links for all the plots.
      </p>
      <h3>Pipeline used</h3>
      <p>
        eQTL detection was performed using gene expression values in CPM
        (corrected by sva + gc_content + quantile_normalization). The effects of
        Population subdivision or staratification was corrected using PCs.
      </p>
      <img src="../assets/Pipeline_2.png" width="800" />
      <h3>Modified steps in the preparation of RNAseq data</h3>
      <img src="../assets/modified_steps.png" width="800" />

      <h3>Top eQTL data Statistics</h3>
      <v-data-table
        dense
        :headers="statHeaders1"
        :items="statistics1"
        hide-default-footer
        class="elevation-1"
      ></v-data-table>
<br>
      <h3>Statistics of filtered eQTL data (based on slope of regression - SNP effect)</h3>

  <p>We filtered out few eQTLs with less significant effect size (slope of the regression). All the top eQTLs significantly associated to the genes and having an effect size of &gt;= 0.5 and &lt;=-0.5 were retained.</p>

 <v-data-table
        dense
        hide-default-header
        :headers="statHeaders2"
        :items="statistics2"
        hide-default-footer
        class="elevation-1"
      ></v-data-table>

    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="closeAction">
        Close
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "PipelineDescription2",

  props: {
    closeAction: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
            statHeaders1: [
        { text: "", value: "name" },
        { text: "Monoculture data", value: "monoculture", align: "end" },
        { text: "Coculture data", value: "coculture", align: "end" },
      ],

      statistics1: [
        {
          monoculture: 516,
          coculture: 402,
          name: "Cis-eQTLs (Pval<10e-4) identified from Monoculture",
        },
        {
          monoculture: 316,
          coculture: 87,
          name: "Cis-eQTLs (FDR_permuted<0.01) identified from Monoculture",
        },
        {
          monoculture: 66,
          coculture: 58,
          name: "Trans-eQTLs (Pval<10e-4) identified from Monoculture",
        },
        {
          monoculture: 2,
          coculture: 3,
          name: "Trans-eQTLs (FDR_permuted<0.01) identified from Monoculture",
        },
        { monoculture: 294, coculture: 252, name: "eQTLs part of ORF" },
        {
          monoculture: 269,
          coculture: 192,
          name: "eQTLs part of Intergenic region",
        },
        {
          monoculture: 2,
          coculture: undefined,
          name: "eQTLs part of Centromeric region",
        },
        {
          monoculture: undefined,
          coculture: 3,
          name: "Cis-eQTLs part of Intronic region",
        },
        {
          monoculture: 16,
          coculture: 12,
          name: "eQTLs part of Exonic region",
        },
        {
          monoculture: 117,
          coculture: 103,
          name: "eQTLs contain non-synonymous mutations",
        },
        {
          monoculture: 63,
          coculture: 34,
          name: "Response eQTLs",
        },
      ],

      statHeaders2: [
        { text: "", value: "name" },
        { text: "", value: "number", align: "end" },
      ],

       statistics2: [
        {
          number: 336,
          name: "eQTLs with significant effect sizes from both the conditions were identified",
        },
        {
          number: 184,
          name: "eQTLs with significant effect size in Monoculture condition",
        },
        {
          number: 152,
          name: "eQTLs with significant effect size in Coculture condition",
        },
        {
          number: 255,
          name: "cis-eQTLs across both conditions",
        },
        {
          number: 81,
          name: "trans-eQTLs across both conditions",
        },
       ]

    };
  },
};
</script>

<style scoped></style>

<template>
  <div :id="elemId"></div>
</template>

<script>
import * as echarts from "echarts/dist/echarts.js";
import "echarts/lib/chart/boxplot";
import axios from "axios";
import renderSlope from "./renderSlope.js";
import renderSlopeBox from "./renderSlopeBox.js";
import renderDataPoints from "./renderDataPoints.js";

export default {
  name: "BoxPlot",

  props: {
    fileName: {
      type: String,
      required: true,
    },
    isFitted: {
      type: Boolean,
      required: false,
    },
    isZoom: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      plotOptions: {},
    };
  },

  computed: {
    elemId: function() {
      const el = this.isFitted ? "box-plot-fitted" : "box-plot";
      const zoomed = this.isZoom ? "zoomed-" : "";
      return zoomed + el;
    },
  },

  mounted: function() {
    axios
      .get(window.location.origin + "/boxplot/" + this.fileName)
      .then((response) => {
        const boxplotData = response.data;
        /*
        const fpSlope = -1.5
        const trSlope = -1
        const fpInt = -3.5
        const trInt = -3.5
        */
        const plotSlopeLabels = function(isZoom) {
          if (isZoom) {
            const fp_data = {
              slope: boxplotData.fp_slope.slope,
              intercept: boxplotData.fp_slope.intercept,
              y_max: boxplotData.y_max,
              y_min: boxplotData.y_min,
              p_val: boxplotData.fp_slope.p_val,
              slope_text: boxplotData.fp_slope.slope_text,
            };

            const tr_data = {
              slope: boxplotData.tr_slope.slope,
              intercept: boxplotData.tr_slope.intercept,
              y_max: boxplotData.y_max,
              y_min: boxplotData.y_min,
              p_val: boxplotData.tr_slope.p_val,
              slope_text: boxplotData.tr_slope.slope_text,
            };

            return [
              {
                name: "Slope FP",
                type: "custom",
                data: [[0, true]],
                renderItem: function(params, api) {
                  return renderSlopeBox(api, "#ee6666", fp_data, tr_data);
                },
                color: "#ee6666",
              },
              {
                name: "Slope TR",
                type: "custom",
                data: [[0, false]],
                renderItem: function(params, api) {
                  return renderSlopeBox(api, "#3ba272", fp_data, tr_data);
                },
                color: "#3ba272",
              },
            ];
          } else {
            return null;
          }
        };

        this.plotOptions = {
          toolbox: {
            show: this.isZoom ? true : false,
            feature: {
              saveAsImage: {},
            },
          },
          title: {
            text: boxplotData.title + (this.isFitted ? " (corrected)" : ""),
            textStyle: { fontSize: 16 },
            left: "center",
            top: 0,
          },
          dataset: [
            {
              source: boxplotData.fp_grouped,
            },
            {
              source: boxplotData.tp_grouped,
            },
            {
              fromDatasetIndex: 0,
              transform: { type: "boxplot" },
            },
            {
              fromDatasetIndex: 1,
              transform: { type: "boxplot" },
            },
            {
              dimensions: ["x", "y", "jitter", "text"],
              source: boxplotData.fp,
            },
            {
              dimensions: ["x", "y", "jitter", "text"],
              source: boxplotData.tp,
            },
          ],
          legend: {
            top: "10%",
            data: [
              {
                name: "FP",
                itemStyle: { color: "#ee6666", borderColor: "#ee6666" },
              },
              {
                name: "TR",
                itemStyle: { color: "#3ba272", borderColor: "#3ba272" },
              },
              {
                name: "Slope FP",
                itemStyle: { color: "#ee6666", borderColor: "#ee6666" },
                icon: "path://M 100 100 L 300 100 L 300 120 L 100 120 z",
              },
              {
                name: "Slope TR",
                itemStyle: { color: "#3ba272", borderColor: "#3ba272" },
                icon: "path://M 100 100 L 300 100 L 300 120 L 100 120 z",
              },
            ],
          },
          tooltip: {
            trigger: "item",
            axisPointer: {
              type: "shadow",
            },
            formatter: function(params) {
              if (params.componentSubType == "boxplot") {
                const text =
                  "max: " +
                  params.data[5].toFixed(2) +
                  "<br>q3: " +
                  params.data[4].toFixed(2) +
                  "<br>median: " +
                  params.data[3].toFixed(2) +
                  "<br>q1: " +
                  params.data[2].toFixed(2) +
                  "<br>min: " +
                  params.data[1].toFixed(2);
                return text;
              } else if (params.componentIndex == 4 && !params.data[7]) {
                return (
                  "<strong>Slope FP</strong><br>p-val: " +
                  params.data[5] +
                  "<br>slope:" +
                  params.data[6]
                );
              } else if (params.componentIndex == 5 && !params.data[7]) {
                return (
                  "<strong>Slope TR</strong><br>p-val: " +
                  params.data[5] +
                  "<br>slope:" +
                  params.data[6]
                );
              } else {
                return params.data.text;
              }
            },
          },
          grid: {
            left: "10%",
            top: "20%",
            right: "10%",
            bottom: "15%",
          },
          xAxis: {
            type: "category",
            boundaryGap: true,
            nameGap: 30,
            splitArea: {
              show: true,
            },
            splitLine: {
              show: false,
            },
            axisLine: { onZero: false },
            name: "Genotype",
            nameLocation: "center",
          },
          yAxis: {
            type: "value",
            name: "Normalized gene expression",
            min: boxplotData.y_min,
            max: boxplotData.y_max,
            splitArea: {
              show: false,
            },
            position: "left",
            nameRotate: 90,
            nameLocation: "center",
            nameTextStyle: { padding: [8, 4, 10, 6] },
          },
          series: [
            {
              name: "FP data",
              type: "custom",
              renderItem: function(params, api) {
                return renderDataPoints(api, "#ee6666", 1);
              },
              itemStyle: {
                borderWidth: 2,
              },
              encode: {
                x: 0,
                y: [0, 1],
              },
              datasetIndex: 4,
              color: "#ee6666",
            },
            {
              name: "TR data",
              type: "custom",
              renderItem: function(params, api) {
                return renderDataPoints(api, "#3ba272", 0);
              },
              itemStyle: {
                borderWidth: 2,
              },
              encode: {
                x: 0,
                y: [0, 1],
              },
              datasetIndex: 5,
              color: "#3ba272",
            },
            {
              name: "FP",
              type: "boxplot",
              datasetIndex: 2,
              color: "#ee6666",
              itemStyle: {
                opacity: 0.7,
              },
            },
            {
              name: "TR",
              type: "boxplot",
              datasetIndex: 3,
              color: "#3ba272",
              itemStyle: {
                opacity: 0.7,
              },
            },
            {
              name: "Slope TR",
              type: "custom",
              data: [
                [
                  0,
                  boxplotData.tr_slope.slope,
                  boxplotData.tr_slope.intercept,
                  boxplotData.y_max,
                  boxplotData.y_min,
                  boxplotData.tr_slope.p_val,
                  boxplotData.tr_slope.slope_text,
                  this.isZoom,
                ],
              ],
              renderItem: function(params, api) {
                return renderSlope(api, "#3ba272");
              },
              color: "#3ba272",
            },
            {
              name: "Slope FP",
              type: "custom",
              data: [
                [
                  0,
                  boxplotData.fp_slope.slope,
                  boxplotData.fp_slope.intercept,
                  boxplotData.y_max,
                  boxplotData.y_min,
                  boxplotData.fp_slope.p_val,
                  boxplotData.fp_slope.slope_text,
                  this.isZoom,
                ],
              ],
              renderItem: function(params, api) {
                return renderSlope(api, "#ee6666");
              },
              color: "#ee6666",
            },
          ].concat(plotSlopeLabels(this.isZoom)),
        };

        let plot = echarts.init(document.getElementById(this.elemId));
        plot.setOption(this.plotOptions);
        plot.resize();
      });
  },
};
</script>

<style lang="scss" scoped>
#box-plot {
  height: 350px;
}
#box-plot-fitted {
  height: 350px;
}
#zoomed-box-plot {
  height: 560px;
}
#zoomed-box-plot-fitted {
  height: 560px;
}
</style>

import _ from "lodash";

function newSlope(data, api) {
  const lineF = function(x) {
    return data.slope * x + data.intercept;
  };

  const start = api.coord([0, lineF(0)]);
  const end = api.coord([1, lineF(1)]);

  const newSlope = (end[1] - start[1]) / (end[0] - start[0]);
  const newIntercept = end[1] - newSlope * end[0];

  const newF = function(x) {
    return newSlope * x + newIntercept;
  };

  return newF;
}

function computeBoxPosY(boxPosX, myFun, slope, boxObj, name) {
  let boxPosY =
    myFun(boxPosX + (slope > 0 ? boxObj.boxW : 0)) -
    boxObj.boxH -
    boxObj.borderH;

  return {
    xPos: boxPosX,
    yPos: boxPosY,
    isFlipped: 0,
    theoPos: boxPosY,
    name: name,
    fun: myFun,
    slope: slope,
  };
}

/*

  const theoPosY = boxPosY;

  let isBelow = false;

  if (boxPosY - boxObj.boxH < boxObj.maxY) {
    const newY =
      myFun(boxPosX + (slope > 0 ? 0 : boxObj.boxW)) + boxObj.borderH;
    boxPosY = newY < boxObj.maxY ? boxObj.maxY : newY;
    isBelow = true;
  }

  if (boxPosY + boxObj.boxH > boxObj.minY) {
    boxPosY = boxObj.minY - boxObj.boxH;
  }
  */

function flipLower(myBox, boxObj) {
  let boxPosY =
    myBox.fun(myBox.xPos + (myBox.slope > 0 ? 0 : boxObj.boxW)) +
    boxObj.borderH;
  return { ...myBox, yPos: boxPosY, isFlipped: 1 };
}

function flipBack(myBox, boxObj) {
  let boxPosY =
    myBox.fun(myBox.xPos + (myBox.slope > 0 ? boxObj.boxW : 0)) -
    boxObj.boxH -
    boxObj.borderH;
  return { ...myBox, yPos: boxPosY, isFlipped: 2 };
}

function spaceUp(orderedPos, boxObj) {
  console.log(orderedPos);
  console.log(boxObj);

  const lowerY = orderedPos[0].yPos + boxObj.boxH + boxObj.borderH;

  if (isOutsideBelow(lowerY + boxObj.boxH, boxObj.minY)) {
    orderedPos[0].yPos = orderedPos[1].yPos - boxObj.boxH - boxObj.borderH;
  } else {
    orderedPos[1].yPos = lowerY;
  }
  return orderedPos;
}

function moveBackInDown(myBox, boxObj) {
  myBox.yPos = boxObj.maxY + boxObj.borderH;
  return myBox;
}

function moveBackInUp(myBox, boxObj) {
  myBox.yPos = boxObj.minY - boxObj.borderH - boxObj.boxH;
  return myBox;
}

function isOutsideUp(yPos, minY) {
  return yPos < minY;
}

function isOutsideBelow(yPos, maxY) {
  return yPos > maxY;
}

const renderSlopeBox = function(api, color, fpData, trData) {
  const isFP = api.value(1);

  const fpFun = newSlope(fpData, api);
  const trFun = newSlope(trData, api);

  const p0 = api.coord([1, fpData.y_min]);
  const p1 = api.coord([2, fpData.y_max]);
  const pDist = (p1[0] - p0[0]) / 2;

  const boxH = 60;
  const boxW = 100;
  const borderH = 10;
  const borderTxt = 10;

  const boxPosX = p1[0] - pDist - boxW / 2;

  const boxObj = {
    boxW: boxW,
    boxH: boxH,
    borderH: borderH,
    maxY: p1[1],
    minY: p0[1],
  };

  let fpPosYObj = computeBoxPosY(boxPosX, fpFun, fpData.slope, boxObj, "fp");
  let trPosYObj = computeBoxPosY(boxPosX, trFun, trData.slope, boxObj, "tr");

  let orderedPos = _.sortBy([fpPosYObj, trPosYObj], "theoPos");

  // if there is an overlap we have to act
  let emergencyBreak = 9;

  const hasOverlap = function(orderedPos, boxObj) {
    return (
      Math.abs(orderedPos[0].yPos - orderedPos[1].yPos) <
      boxObj.boxH + boxObj.borderH
    );
  };

  const isOutside = function(orderedPos, boxObj) {
    return (
      isOutsideUp(orderedPos[0].yPos, boxObj.maxY) ||
      isOutsideBelow(orderedPos[0].yPos + boxObj.boxH, boxObj.minY) ||
      isOutsideUp(orderedPos[1].yPos, boxObj.maxY) ||
      isOutsideBelow(orderedPos[1].yPos + boxObj.boxH, boxObj.minY)
    );
  };

  const correctOutsideOut = function(orderedPosEl, boxObj) {
    if (isOutsideUp(orderedPosEl.yPos, boxObj.maxY)) {
      if (!orderedPosEl.isFlipped) {
        orderedPosEl = flipLower(orderedPosEl, boxObj);
      } else {
        orderedPosEl = moveBackInDown(orderedPosEl, boxObj);
      }
    }
    return orderedPosEl;
  };

  while (
    emergencyBreak &&
    (hasOverlap(orderedPos, boxObj) || isOutside(orderedPos, boxObj))
  ) {
    if (hasOverlap(orderedPos, boxObj)) {
      console.log("hasOverlap");
      if (!orderedPos[0].isFlipped && !orderedPos[1].isFlipped) {
        console.log("flip");
        orderedPos[1] = flipLower(orderedPos[1], boxObj);
      } else {
        orderedPos = spaceUp(orderedPos, boxObj);
      }
    }

    orderedPos[0] = correctOutsideOut(orderedPos[0], boxObj);
    orderedPos[1] = correctOutsideOut(orderedPos[1], boxObj);

    console.log(orderedPos[1].yPos, boxObj.minY);

    if (isOutsideBelow(orderedPos[0].yPos + boxObj.boxH, boxObj.minY)) {
      console.log("higher is outside below");
    }

    if (isOutsideBelow(orderedPos[1].yPos + boxObj.boxH, boxObj.minY)) {
      console.log("lower is outside below");
      if (orderedPos[1].isFlipped == 1) {
        console.log("flip back");
        orderedPos[1] = flipBack(orderedPos[1], boxObj);
      } else {
        console.log("move up");
        orderedPos[1] = moveBackInUp(orderedPos[1], boxObj);
      }
    }

    emergencyBreak--;
  }

  const fpPosY = orderedPos.find((f) => {
    return f.name == "fp";
  }).yPos;
  const trPosY = orderedPos.find((f) => {
    return f.name == "tr";
  }).yPos;

  return {
    type: "group",
    children: [
      {
        type: "rect",
        x: boxPosX,
        y: isFP ? fpPosY : trPosY,
        shape: {
          width: boxW,
          height: boxH,
          r: 5,
        },
        style: {
          fill: "white",
          stroke: color,
          lineWidth: 1,
        },
        styleEmphasis: {
          fill: "white",
          stroke: color,
          opacity: 1,
          lineWidth: 2,
          shadowBlur: 6,
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowColor: "rgba(0,0,0,0.3)",
        },
      },
      {
        type: "text",
        x: boxPosX + borderTxt,
        y: (isFP ? fpPosY : trPosY) + borderTxt,
        style: {
          text: "Slope " + (isFP ? "FP" : "TR"),
          fontWeight: "bold",
          fill: "#606060",
        },
        styleEmphasis: {
          fill: "black",
        },
      },
      {
        type: "text",
        x: boxPosX + borderTxt,
        y: (isFP ? fpPosY : trPosY) + 25,
        style: {
          text: [
            "p-val: " + (isFP ? fpData.p_val : trData.p_val),
            "slope: " + (isFP ? fpData.slope_text : trData.slope_text),
          ].join("\n"),
          fill: "#606060",
        },
        styleEmphasis: {
          fill: "black",
        },
      },
    ],
  };
};

export default renderSlopeBox;

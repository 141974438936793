<template>
  <div id="number-filter">
    <v-card v-if="isOpen" id="filter-slider" elevation="2">
      <v-range-slider
        id="range-slider"
        :max="maxValue"
        :min="minValue"
        :step="stepSize"
        v-model="range"
        v-click-outside="closeSelection"
      ></v-range-slider>
      <span id="slider-min">{{ range[0] }}</span>
      <span id="slider-max">{{ range[1] }}</span>
    </v-card>
    <span v-else @click="openSelection">
      {{ allSelected ? "All" : "&lt; - &gt;" }}
    </span>
  </div>
</template>

<script>
export default {
  name: "RangeSelection",

  props: {
    minValue: {
      type: String,
      required: true,
    },
    maxValue: {
      type: String,
      required: true,
    },
    stepSize: {
      type: String,
      required: true,
    },
    selRange: {
      type: Function,
      required: true
    }
  },

  methods: {
    openSelection() {
      this.isOpen = true;
    },
    closeSelection() {
      this.isOpen = false;
    },
  },

  data() {
    return {
      isOpen: false,
      range: [this.minValue, this.maxValue],
      allSelected: true
    };
  },

  watch: {
    range(value) {
      this.allSelected = (value[0] <= Number(this.minValue) && value[1] >= Number(this.maxValue)) ? true : false
      this.$emit("input", {range: value, allSelected: this.allSelected})
      this.selRange(value)
    },
  },
};
</script>

<style lang="scss" scoped>
#number-filter {
  position: relative;
  overflow: visible;
}
#filter-slider {
  position: absolute;
  overflow: visible;
  white-space: nowrap;
  display: block;
  min-width: 150px !important;
  height: 100px;
  top: -20px;
  right: 0px;
}

#range-slider {
  width: 150px;
}

#slider-min {
  font-size: small;
  position: absolute;
  top: 27px;
  left: 5px;
}

#slider-max {
  font-size: small;
  position: absolute;
  top: 27px;
  right: 5px;
}

</style>

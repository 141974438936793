<template>
  <v-row>
    <v-col v-if="type == 'volcano'">
      <VolcanoPlot :fileName="fileName" :isZoom="true"></VolcanoPlot>
    </v-col>
        <v-col v-else-if="type == 'manhattan'">
      <ManhattanPlot :fileName="fileName" :isZoom="true"></ManhattanPlot>
    </v-col>
        <v-col v-else-if="type == 'boxplot'">
      <BoxPlot :fileName="fileName" :isZoom="true" :key="fileName"></BoxPlot>
    </v-col>
            <v-col v-else>
      <BoxPlot :fileName="fileName" :isFitted="true" :isZoom="true" :key="fileName"></BoxPlot>
    </v-col>
  </v-row>
</template>

<script>
import VolcanoPlot from "./VolcanoPlot";
import ManhattanPlot from "./ManhattanPlot";
import BoxPlot from "./BoxPlot";

export default {
  name: "ZoomPlot",

   components: {
    VolcanoPlot,
    ManhattanPlot,
    BoxPlot
  },

  props: {
    fileName: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true
    }
  },
};
</script>

<style lang="scss" scoped>
</style>

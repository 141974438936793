function renderDataPoints(api, color, dataGroup) {
  var xValue = api.value(0) - 1;

  var barLayout = api.barLayout({
    barGap: "30%",
    barCategoryGap: "20%",
    count: 2,
  });

  const point = api.coord([xValue, api.value(1)]);
  const jitterOffset = api.value(2);

  point[0] -= barLayout[dataGroup].offsetCenter + jitterOffset;

  return {
    type: "circle",
    shape: {
      cx: point[0],
      cy: point[1],
      r: 5,
    },
    style: {
      fill: color,
      opacity: 0.2,
    },
    styleEmphasis: {
      fill: color,
      opacity: 0.9,
    },
  };
}

export default renderDataPoints;

<template>
  <div :id="isZoom ? 'zoomed-volcano-plot' : 'volcano-plot'"></div>
</template>

<script>
import * as echarts from "echarts/dist/echarts.js";
import "echarts/lib/chart/scatter";
import axios from "axios";

export default {
  name: "VolcanoPlot",

  props: {
    fileName: {
      type: String,
      required: true,
    },
    isZoom: {
      type: Boolean,
      required: false
    }
  },

  data() {
    return {
      plotOptions: {},
    };
  },

  mounted: function() {
    axios
      .get(window.location.origin + "/volcano/" + this.fileName)
      .then((response) => {
        const volcanoPlotData = response.data;

        this.plotOptions = {
          toolbox: {
            show: this.isZoom ? true : false,
            feature: {
              dataZoom: {},
              saveAsImage: {},
            },
          },
          title: {
            text: volcanoPlotData.title,
            textStyle: { fontSize: 16 },
            left: "center",
            top: 0,
          },
          tooltip: {
            showDelay: 0,
            formatter: function(params) {
              if (params.componentType == "markLine") {
                const text =
                  params.data.name + " threshold: " + params.data.value;
                return text;
              } else {
                const text =
                  params.data.text + "<br>-Log10(p): " + params.data.y;
                return text;
              }
            },
          },
          xAxis: {
            name: "EFFECTSIZE",
            nameLocation: "center",
            nameTextStyle: { padding: [8, 4, 5, 6] },
          },
          yAxis: {
            min: 1,
            name: "-log10(p)",
            position: "left",
            nameRotate: 90,
            nameLocation: "center",
            nameTextStyle: { padding: [8, 4, 5, 6] },
          },
          dataset: [
            {
              dimensions: ["x", "y"],
              source: volcanoPlotData.data,
            },
            {
              dimensions: ["x", "y"],
              source: volcanoPlotData.signif,
            },
          ],
          series: [
            {
              symbolSize: 5,
              datasetIndex: 1,
              type: "scatter",
              color: "#ee6666",
              markLine: {
                lineStyle: {
                  type: "dashed",
                  color: "#3ba272",
                },
                label: { show: false },
                symbol: ["none", "none"],
                data: [
                  {
                    xAxis: volcanoPlotData.thresholds["x"][0],
                    name: "EFFECTSIZE",
                  },
                  {
                    xAxis: volcanoPlotData.thresholds["x"][1],
                    name: "EFFECTSIZE",
                  },
                  {
                    yAxis: volcanoPlotData.thresholds["y"][0],
                    name: "-log10(p)",
                  },
                ],
              },
            },
            {
              symbolSize: 5,
              datasetIndex: 0,
              type: "scatter",
              itemStyle: {
                opacity: 0.5,
              },
            },
          ],
        };

        const elemId = this.isZoom ? "zoomed-volcano-plot" : "volcano-plot"
        let volcanoPlot = echarts.init(document.getElementById(elemId));
        volcanoPlot.setOption(this.plotOptions);
        volcanoPlot.resize();
      });
  },
};
</script>

<style lang="scss" scoped>
#volcano-plot {
  height: 350px;
}
#zoomed-volcano-plot {
  height: 560px;
}

</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('v-container',[_c('v-row',{staticClass:"text-left"},[_c('v-col',{attrs:{"cols":"10"}},[_c('h2',[_c('strong',[_vm._v(_vm._s(_vm.name)+":")]),_vm._v(" "+_vm._s(_vm.title)+" "),_c('span',{staticStyle:{"visibility":"hidden"}},[_vm._v(_vm._s(_vm.selTabChanged))])])]),_c('v-col',{attrs:{"cols":"2"}},[_c('v-row',[_c('v-col',{attrs:{"align":"right"}},[_c('v-dialog',{attrs:{"width":"900","max-width":"900"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","text":"","right":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.name + " description")+" ")])]}}]),model:{value:(_vm.descriptionDialog),callback:function ($$v) {_vm.descriptionDialog=$$v},expression:"descriptionDialog"}},[(_vm.name === 'Pipeline 1')?_c('PipelineDescription1',{attrs:{"closeAction":_vm.closeDescriptionDialog}}):_c('PipelineDescription2',{attrs:{"closeAction":_vm.closeDescriptionDialog}})],1)],1)],1)],1)],1),_c('v-tabs',{model:{value:(_vm.localSelTab[_vm.pipelineId]),callback:function ($$v) {_vm.$set(_vm.localSelTab, _vm.pipelineId, $$v)},expression:"localSelTab[pipelineId]"}},[_c('v-tab',{on:{"click":function($event){return _vm.selectTab({ pipelineId: _vm.pipelineId, idx: -1 })}}},[_vm._v("Table")]),_vm._l((_vm.openTabs[_vm.pipelineId]),function(tab,index){return _c('v-tab',{key:'tab' + _vm.pipelineId + index,on:{"click":function($event){return _vm.selectTab({ pipelineId: _vm.pipelineId, idx: index })}}},[_vm._v(" "+_vm._s(_vm.openTabNames[_vm.pipelineId][index])+" "),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.closeTab({
              pipelineId: _vm.pipelineId,
              tabName: _vm.openTabNames[_vm.pipelineId][index],
              id: tab.isZoom ? tab.type : tab.id,
              isZoom: tab.isZoom
            })}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-close ")])],1)],1)})],2),(_vm.selTab[_vm.pipelineId] === -1)?_c('div',[_c('v-row',{staticClass:"text-center"},[_c('v-col',{attrs:{"cols":"12"}},[_c('PipelineTable',{attrs:{"headers":_vm.headers,"resTable":_vm.resTable,"filteredResTable":_vm.filteredResTable}})],1)],1)],1):(_vm.openTabs[_vm.pipelineId][_vm.selTab[_vm.pipelineId]].isZoom)?_c('div',[_c('ZoomPlot',{attrs:{"fileName":_vm.openTabs[_vm.pipelineId][_vm.selTab[_vm.pipelineId]].fileName,"type":_vm.openTabs[_vm.pipelineId][_vm.selTab[_vm.pipelineId]].type}})],1):_c('div',[_c('v-row',{staticClass:"text-right"},[_c('v-col',{attrs:{"cols":"6"}},[_c('div',[_c('VolcanoPlot',{key:_vm.selTab[_vm.pipelineId],attrs:{"fileName":_vm.openTabs[_vm.pipelineId][_vm.selTab[_vm.pipelineId]].volcano.fileName}}),_c('v-btn',{staticClass:"plot-button",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.zoomPlot({
                  type: 'volcano',
                  data: _vm.openTabs[_vm.pipelineId][_vm.selTab[_vm.pipelineId]],
                })}}},[_c('v-icon',[_vm._v(" mdi-magnify-expand ")])],1)],1)]),_c('v-col',{attrs:{"cols":"6"}},[_c('ManhattanPlot',{key:_vm.selTab[_vm.pipelineId],attrs:{"fileName":_vm.openTabs[_vm.pipelineId][_vm.selTab[_vm.pipelineId]].manhattan.fileName}}),_c('v-btn',{staticClass:"plot-button",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.zoomPlot({
                type: 'manhattan',
                data: _vm.openTabs[_vm.pipelineId][_vm.selTab[_vm.pipelineId]],
              })}}},[_c('v-icon',[_vm._v(" mdi-magnify-expand ")])],1)],1)],1),_c('v-row',{staticClass:"text-right"},[_c('v-col',{attrs:{"cols":"6"}},[_c('BoxPlot',{key:_vm.selTab[_vm.pipelineId],attrs:{"fileName":_vm.openTabs[_vm.pipelineId][_vm.selTab[_vm.pipelineId]].boxplot.fileName}}),_c('v-btn',{staticClass:"plot-button",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.zoomPlot({
                type: 'boxplot',
                data: _vm.openTabs[_vm.pipelineId][_vm.selTab[_vm.pipelineId]],
              })}}},[_c('v-icon',[_vm._v(" mdi-magnify-expand ")])],1)],1),(_vm.openTabs[_vm.pipelineId][_vm.selTab[_vm.pipelineId]].boxplotFitted)?_c('v-col',{attrs:{"cols":"6"}},[_c('BoxPlot',{key:_vm.selTab[_vm.pipelineId] + 'fitted',attrs:{"fileName":_vm.openTabs[_vm.pipelineId][_vm.selTab[_vm.pipelineId]].boxplotFitted.fileName,"isFitted":true}}),_c('v-btn',{staticClass:"plot-button",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.zoomPlot({
                type: 'boxplotFitted',
                data: _vm.openTabs[_vm.pipelineId][_vm.selTab[_vm.pipelineId]],
              })}}},[_c('v-icon',[_vm._v(" mdi-magnify-expand ")])],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
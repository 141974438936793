<template>
  <v-main>
    <v-container>
      <v-row class="text-left">
        <v-col cols="10">
          <h2>
            <strong>{{ name }}:</strong> {{ title }}
            <span style="visibility: hidden">{{ selTabChanged }}</span>
          </h2>
        </v-col>
        <v-col cols="2">
          <v-row>
            <v-col align="right">
              <v-dialog v-model="descriptionDialog" width="900" max-width="900">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    text
                    right
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                  >
                    {{ name + " description" }}
                  </v-btn>
                </template>
                <PipelineDescription1
                  v-if="name === 'Pipeline 1'"
                  :closeAction="closeDescriptionDialog"
                ></PipelineDescription1>
                <PipelineDescription2
                  v-else
                  :closeAction="closeDescriptionDialog"
                ></PipelineDescription2>
              </v-dialog>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-tabs v-model="localSelTab[pipelineId]">
        <v-tab @click="selectTab({ pipelineId: pipelineId, idx: -1 })"
          >Table</v-tab
        >
        <v-tab
          v-for="(tab, index) in openTabs[pipelineId]"
          :key="'tab' + pipelineId + index"
          @click="selectTab({ pipelineId: pipelineId, idx: index })"
        >
          {{ openTabNames[pipelineId][index] }}
          <v-btn
            icon
            @click.stop="
              closeTab({
                pipelineId: pipelineId,
                tabName: openTabNames[pipelineId][index],
                id: tab.isZoom ? tab.type : tab.id,
                isZoom: tab.isZoom
              })
            "
          >
            <v-icon small>
              mdi-close
            </v-icon>
          </v-btn>
        </v-tab>
      </v-tabs>

      <div v-if="selTab[pipelineId] === -1">
        <v-row class="text-center">
          <v-col cols="12">
            <PipelineTable
              :headers="headers"
              :resTable="resTable"
              :filteredResTable="filteredResTable"
            >
            </PipelineTable>
          </v-col>
        </v-row>
      </div>
      <div v-else-if="openTabs[pipelineId][selTab[pipelineId]].isZoom">
        <ZoomPlot
          :fileName="openTabs[pipelineId][selTab[pipelineId]].fileName"
          :type="openTabs[pipelineId][selTab[pipelineId]].type"
        ></ZoomPlot>
      </div>
      <div v-else>
        <v-row class="text-right">
          <v-col cols="6">
            <div>
              <VolcanoPlot
                :fileName="
                  openTabs[pipelineId][selTab[pipelineId]].volcano.fileName
                "
                :key="selTab[pipelineId]"
              ></VolcanoPlot>
              <v-btn
                class="plot-button"
                icon
                @click.stop="
                  zoomPlot({
                    type: 'volcano',
                    data: openTabs[pipelineId][selTab[pipelineId]],
                  })
                "
              >
                <v-icon>
                  mdi-magnify-expand
                </v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-col cols="6">
            <ManhattanPlot
              :fileName="
                openTabs[pipelineId][selTab[pipelineId]].manhattan.fileName
              "
              :key="selTab[pipelineId]"
            ></ManhattanPlot>
            <v-btn
              class="plot-button"
              icon
              @click.stop="
                zoomPlot({
                  type: 'manhattan',
                  data: openTabs[pipelineId][selTab[pipelineId]],
                })
              "
            >
              <v-icon>
                mdi-magnify-expand
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="text-right">
          <v-col cols="6">
            <BoxPlot
              :fileName="
                openTabs[pipelineId][selTab[pipelineId]].boxplot.fileName
              "
              :key="selTab[pipelineId]"
            >
            </BoxPlot>
            <v-btn
              class="plot-button"
              icon
              @click.stop="
                zoomPlot({
                  type: 'boxplot',
                  data: openTabs[pipelineId][selTab[pipelineId]],
                })
              "
            >
              <v-icon>
                mdi-magnify-expand
              </v-icon>
            </v-btn>
          </v-col>
          <v-col
            cols="6"
            v-if="openTabs[pipelineId][selTab[pipelineId]].boxplotFitted"
          >
            <BoxPlot
              :fileName="
                openTabs[pipelineId][selTab[pipelineId]].boxplotFitted.fileName
              "
              :isFitted="true"
              :key="selTab[pipelineId] + 'fitted'"
            >
            </BoxPlot>
            <v-btn
              class="plot-button"
              icon
              @click.stop="
                zoomPlot({
                  type: 'boxplotFitted',
                  data: openTabs[pipelineId][selTab[pipelineId]],
                })
              "
            >
              <v-icon>
                mdi-magnify-expand
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </v-main>
</template>

<script>
import PipelineTable from "./PipelineTable";
import VolcanoPlot from "./VolcanoPlot";
import ManhattanPlot from "./ManhattanPlot";
import ZoomPlot from "./ZoomPlot";
import PipelineDescription1 from "./PipelineDescription1.vue";
import PipelineDescription2 from "./PipelineDescription2.vue";
import BoxPlot from "./BoxPlot";
import { mapState } from "vuex";

export default {
  name: "Pipeline",

  components: {
    PipelineTable,
    VolcanoPlot,
    ManhattanPlot,
    BoxPlot,
    ZoomPlot,
    PipelineDescription1,
    PipelineDescription2,
  },

  props: {
    pipelineId: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    resTable: {
      type: Array,
      required: true,
    },
    filteredResTable: {
      type: Array,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState({
      openTabs: (state) => state.tabs.openTabs,
      openTabNames: (state) => state.tabs.openTabNames,
      selTab: (state) => state.tabs.selTab,
      selTabChanged: (state) => state.tabs.selTabChanged,
    }),
  },

  methods: {
    selectTab: function(tabIdx) {
      this.$store.commit("tabs/selectTab", tabIdx);
    },
    closeTab: function(tabName) {
      this.$store.commit("tabs/closeTab", tabName);
    },
    closeDescriptionDialog: function() {
      this.descriptionDialog = false;
    },
    zoomPlot: function(payload) {
      let name = payload.data[payload.type].title;
      if (payload.type == "volcano" || payload.type == "manhattan") {
        name += "-" + payload.type;
      }

      if(payload.type == "boxplotFitted"){
        name = name.replace("fitted", "corrected")
      }

      const zoomObj = {
        isZoom: true,
        fileName: payload.data[payload.type].fileName,
        name: name,
        type: payload.type,
        rowId: payload.data.id,
        pipelineId: this.pipelineId,
      };

      this.$store.commit("tabs/addTab", zoomObj);
    },
  },

  data() {
    return {
      statsExpanded: false,
      descriptionDialog: false,
      selectedTab: -1,
      localSelTab: [0, 0],
    };
  },

  watch: {
    pipelineId: {
      handler() {
        this.localSelTab[this.pipelineId] =
          this.$store.state.tabs.selTab[this.pipelineId] + 1;
      },
      immediate: true,
    },

    "$store.state.tabs.selTabChanged": {
      handler() {
        if (this.selTab[this.pipelineId] === -1) {
          this.localSelTab[this.pipelineId] = 0;
        } else if (
          this.selTab[this.pipelineId] + 1 !==
          this.localSelTab[this.pipelineId]
        ) {
          this.localSelTab[this.pipelineId] = this.selTab[this.pipelineId] + 1;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
.plot-button {
  top: -350px;
  right: 40px;
}
</style>

<template>
  <v-card>
    <v-card-title class="text-h5">
      Pipeline 1 description
    </v-card-title>

    <v-card-text class="text-left">
      <h2>Top eQTL data from Monoculture and Coculture</h2>
      <br>
      <h3>Gene expression values were converted to CPM (corrected by sva + trimmed mean of M values (TMM) normalization)</h3>
      <p>We display the Top eQTLs (Cis and Trans) from Candida albicans from Monoculture (Isolates grown in Normal Tissue Culture medium) and Coculture (Isolates grown in Normal Tissue Culture medium in the presence of TR146 cells).
      </p>
      <h3>Pipeline used</h3>
      <p>eQTL detection was performed using gene expression values in CPM (corrected by sva + trimmed mean of M values (TMM) normalization). The effects of Population subdivision or stratification was corrected using PCs.</p>
      <img src="../assets/Pipeline1_workflow.png" width="800"/>
      <p>The Data tables were generated using ‘Kable’ or ‘DT’ packages in R. Each eQTL in the data table contains clickable links for all the plots.</p>

      <h3>Top eQTL data Statistics</h3>
      <v-data-table
        dense
        :headers="statHeaders"
        :items="statistics"
        hide-default-footer
        class="elevation-1"
      ></v-data-table>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="closeAction">
        Close
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "PipelineDescription1",

  props: {
    closeAction: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      statHeaders: [
        { text: "", value: "name" },
        { text: "Top eQTL", value: "number", align: "end" },
        { text: "Filtered eQTL", value: "filtered", align: "end" },
      ],
      statistics: [
        {
          number: 862,
          filtered: 519,
          name: "eQTLs identified from Monoculture",
        },
        { number: 960, filtered: 606, name: "eQTLs identified from Coculture" },
        { number: 503, filtered: 243, name: "Cis-eQTLs across the conditions" },
        {
          number: 1320,
          filtered: 882,
          name: "Trans-eQTLs across the conditions",
        },
        {
          number: 71,
          filtered: null,
          name: "True-eQTLs (i.e the eQTLs found in both conditions)",
        },
        {
          number: 1680,
          filtered: 1125,
          name: "Response eQTLs (i.e the eQTLs found in either condition)",
        },
      ],
    };
  },
};
</script>

<style scoped></style>

import { render, staticRenderFns } from "./RangeSelection.vue?vue&type=template&id=54d08e3c&scoped=true&"
import script from "./RangeSelection.vue?vue&type=script&lang=js&"
export * from "./RangeSelection.vue?vue&type=script&lang=js&"
import style0 from "./RangeSelection.vue?vue&type=style&index=0&id=54d08e3c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54d08e3c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VRangeSlider } from 'vuetify/lib/components/VRangeSlider';
installComponents(component, {VCard,VRangeSlider})


/* vuetify-loader */
import installDirectives from "!../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"transparent",attrs:{"flat":""}},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-switch',{attrs:{"label":"Filter eQTL data"},model:{value:(_vm.filterTable),callback:function ($$v) {_vm.filterTable=$$v},expression:"filterTable"}}),_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"blue darken-2"}},[_vm._v(" mdi-information ")])],1)]}}]),model:{value:(_vm.filterDialog),callback:function ($$v) {_vm.filterDialog=$$v},expression:"filterDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Filter eQTL data ")]),_c('v-card-text',{staticClass:"text-left"},[_vm._v(" Filter out few eQTLs with less significant effect size (slope of the regression). All the top eQTLs significantly associated to the genes and having an effect size of >= 0.5 and <=-0.5 are retained. ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.filterDialog = false}}},[_vm._v(" Close ")])],1)],1)],1)],1),_c('v-data-table',{ref:"vtable",attrs:{"headers":_vm.headersWithPlots,"items":_vm.activeTable,"items-per-page":10,"custom-sort":_vm.columnSort,"custom-filter":_vm.filterText,"search":_vm.search},scopedSlots:_vm._u([_vm._l((_vm.headersWithPlots),function(header){return {key:("header." + (header.value)),fn:function(){return [_c('v-tooltip',{key:header.value,attrs:{"top":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(header.value))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(header.description))])])]},proxy:true}}),{key:"body",fn:function(props){return [_vm._l((props.items),function(item,index){return _c('tr',{key:index},_vm._l((_vm.headersWithPlots),function(header,i){return _c('td',{key:header.value,class:_vm.tableCellClass(header)},[(item[header.value].link)?_c('span',[_c('a',{attrs:{"href":item[header.value].link,"target":"_blank"}},[_vm._v(_vm._s(item[header.value].value))])]):(header.value === 'Plots')?_c('span',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openPlot(item[header.value])}}},[_c('v-icon',{attrs:{"color":"blue darken-2"}},[_vm._v(" mdi-chart-box ")])],1)],1):(header.viz_type === 'ellipsis')?_c('span',[_c('v-tooltip',{key:header.value + i,attrs:{"top":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(item[header.value].value.substring(0, 30) + "..."))])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item[header.value].value)+" ")])])],1):_c('span',[_vm._v(_vm._s(item[header.value].value))])])}),0)}),_c('tr',[_vm._l((_vm.headersWithPlots),function(h,index){return _c('td',{key:index},[(h.filter_type === 'character')?_c('v-text-field',{attrs:{"type":"character","label":h.text},model:{value:(_vm.columnFilterTexts[h.value]),callback:function ($$v) {_vm.$set(_vm.columnFilterTexts, h.value, $$v)},expression:"columnFilterTexts[h.value]"}}):(h.filter_type === 'multi_sel')?_c('v-select',{attrs:{"items":_vm.multiSelItems[_vm.filterTable ? 1 : 0][h.value],"label":h.text},model:{value:(_vm.multiFilterSelections[h.value]),callback:function ($$v) {_vm.$set(_vm.multiFilterSelections, h.value, $$v)},expression:"multiFilterSelections[h.value]"}}):(h.filter_type === 'number')?_c('RangeSelection',{attrs:{"minValue":_vm.filterNumberRanges[_vm.filterTable ? 1 : 0][h.value][0],"maxValue":_vm.filterNumberRanges[_vm.filterTable ? 1 : 0][h.value][1],"stepSize":_vm.filterNumberRanges[_vm.filterTable ? 1 : 0][h.value][2],"selRange":_vm.changeNumberRange},model:{value:(_vm.numberFilterSelections[h.value]),callback:function ($$v) {_vm.$set(_vm.numberFilterSelections, h.value, $$v)},expression:"numberFilterSelections[h.value]"}}):_vm._e()],1)}),_c('td',{attrs:{"colspan":"4"}})],2)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
const renderSlope = function(api, color) {
  const yMax = api.value(3);
  const yMin = api.value(4);

  const slope = api.value(1);
  const intercept = api.value(2);

  const lineF = function(x) {
    return slope * x + intercept;
  };

  const start = api.coord([0, lineF(0)]);
  const end = api.coord([1, lineF(1)]);
  const dist = (end[0] - start[0]) / 2;

  const newSlope = (end[1] - start[1]) / (end[0] - start[0]);
  const newIntercept = end[1] - newSlope * end[0];

  const minYCoord = api.coord([0, yMin])[1];
  const maxYCoord = api.coord([0, yMax])[1];

  const xMax = (maxYCoord - newIntercept) / newSlope;
  const xMin = (minYCoord - newIntercept) / newSlope;

  const newF = function(x) {
    return newSlope * x + newIntercept;
  };

  let startX = start[0] - dist;
  let endX = end[0] + 3 * dist;

  // make sure that the lines stay within the graph
  startX =
    newSlope > 0
      ? startX < xMax
        ? xMax
        : startX
      : startX < xMin
      ? xMin
      : startX;
  endX = newSlope > 0 ? (endX > xMin ? xMin : endX) : endX > xMax ? xMax : endX;

  return {
    type: "group",
    children: [
      {
        type: "line",
        shape: {
          x1: startX,
          x2: endX,
          y1: newF(startX),
          y2: newF(endX),
        },
        style: {
          stroke: color,
          lineWidth: 2,
          lineDash: [10],
          opacity: 0.6,
        },
        styleEmphasis: {
          stroke: color,
          lineWidth: 4,
          lineDash: [10],
        },
      },
    ],
  };
};

export default renderSlope;

<template>
  <v-card flat class="transparent">
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-switch v-model="filterTable" label="Filter eQTL data"></v-switch>

      <v-dialog v-model="filterDialog" width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon color="blue darken-2">
              mdi-information
            </v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5">
            Filter eQTL data
          </v-card-title>

          <v-card-text class="text-left">
            Filter out few eQTLs with less significant effect size (slope of the
            regression). All the top eQTLs significantly associated to the genes
            and having an effect size of >= 0.5 and &lt;=-0.5 are retained.
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="filterDialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-title>

    <v-data-table
      ref="vtable"
      :headers="headersWithPlots"
      :items="activeTable"
      :items-per-page="10"
      :custom-sort="columnSort"
      :custom-filter="filterText"
      :search="search"
    >
      <template
        v-for="header in headersWithPlots"
        v-slot:[`header.${header.value}`]
      >
        <v-tooltip top :key="header.value" max-width="400">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{ header.value }}</span>
          </template>
          <span>{{ header.description }}</span>
        </v-tooltip>
      </template>

      <template v-slot:body="props">
        <tr v-for="(item, index) in props.items" :key="index">
          <td
            v-for="(header, i) in headersWithPlots"
            :key="header.value"
            :class="tableCellClass(header)"
          >
            <span v-if="item[header.value].link">
              <a :href="item[header.value].link" target="_blank">{{
                item[header.value].value
              }}</a>
            </span>
            <span v-else-if="header.value === 'Plots'">
              <v-btn icon @click="openPlot(item[header.value])">
                <v-icon color="blue darken-2">
                  mdi-chart-box
                </v-icon>
              </v-btn>
            </span>
            <span v-else-if="header.viz_type === 'ellipsis'">
              <v-tooltip top :key="header.value + i" max-width="400">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">{{
                    item[header.value].value.substring(0, 30) + "..."
                  }}</span>
                </template>
                <span> {{ item[header.value].value }} </span>
              </v-tooltip>
            </span>
            <span v-else>{{ item[header.value].value }}</span>
          </td>
        </tr>

        <tr>
          <td v-for="(h, index) in headersWithPlots" :key="index">
            <v-text-field
              v-if="h.filter_type === 'character'"
              type="character"
              :label="h.text"
              v-model="columnFilterTexts[h.value]"
            >
            </v-text-field>
            <v-select
              v-else-if="h.filter_type === 'multi_sel'"
              :items="multiSelItems[filterTable ? 1 : 0][h.value]"
              :label="h.text"
              v-model="multiFilterSelections[h.value]"
            ></v-select>
            <RangeSelection
              v-else-if="h.filter_type === 'number'"
              :minValue="filterNumberRanges[filterTable ? 1 : 0][h.value][0]"
              :maxValue="filterNumberRanges[filterTable ? 1 : 0][h.value][1]"
              :stepSize="filterNumberRanges[filterTable ? 1 : 0][h.value][2]"
              :selRange="changeNumberRange"
              v-model="numberFilterSelections[h.value]"
            ></RangeSelection>
          </td>
          <td colspan="4"></td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import _ from "lodash";
import RangeSelection from "./RangeSelection";

export default {
  name: "PipelineTable",

  components: {
    RangeSelection,
  },

  props: {
    resTable: {
      type: Array,
      required: true,
    },
    filteredResTable: {
      type: Array,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
  },

  created() {
    this.activeTable = this.resTable;

    //*******************************************
    // prepare ranges for filtering the numbers
    //*******************************************
    const numHeaders = this.headers
      .filter((h) => {
        return h.filter_type === "number";
      })
      .map((h) => {
        return h.value;
      });

    const createRanges = function(resTable) {
      const ranges = _.reduce(
        resTable,
        function(res, val) {
          numHeaders.forEach(function(h) {
            const v = Number(val[h].value);
            if (!res[h]) {
              res[h] = [v, v];
            } else if (v < res[h][0]) {
              res[h][0] = v;
            } else if (v > res[h][1]) {
              res[h][1] = v;
            }
          });
          return res;
        },
        {}
      );

      const roundedRanges = _.mapValues(ranges, (o) => {
        // if min is smaller then 0.01 we put 0
        if (o[0] > 0 && o[0] < 0.01) o[0] = 0;
        // if max is smaller then 0.1 we put 0.1
        if (o[1] < 0.1) o[1] = 0.1;
        const range = Math.pow(10, Math.floor(Math.log10(o[1] - o[0])));
        const nrTicks = 100;
        return [String(o[0]), String(o[1]), String(range / nrTicks)];
      });

      return roundedRanges;
    };

    const fltRanges = createRanges(this.resTable);
    const fltRangesFlt = createRanges(this.filteredResTable);

    numHeaders.forEach((h) => {
      this.numberFilterSelections[h] = {
        range: [Number(fltRanges[h][0]), Number(fltRanges[h][1])],
        allSelected: true,
      };
    });

    this.filterNumberRanges = [fltRanges, fltRangesFlt];

    //*******************************************
    // prepare filtering for multi select items
    //*******************************************
    const headersWithMulitSel = this.headers
      .filter((h) => {
        return h.filter_type === "multi_sel";
      })
      .map((h) => {
        return h.value;
      });

    const createMulitSel = function(resTable) {
      return _.reduce(
        resTable,
        function(res, val) {
          headersWithMulitSel.forEach(function(h) {
            if (
              val[h] &&
              val[h].value &&
              !(res[h] && res[h].includes(val[h].value))
            ) {
              if (res[h]) {
                res[h].push(val[h].value);
              } else {
                res[h] = ["All", val[h].value];
              }
            }
          });

          return res;
        },
        {}
      );
    };

    const multiSel = createMulitSel(this.resTable);
    const multiSelFlt = createMulitSel(this.filteredResTable);

    this.multiSelItems = [multiSel, multiSelFlt];

    //*******************************************
    // prepare headers, add the plots at the beginning
    //*******************************************
    this.headersWithPlots = [
      { text: "", value: "Plots", is_link: false },
    ].concat(this.headers);
  },

  data() {
    return {
      search: "",
      columnFilterTexts: {},
      multiFilterSelections: {},
      numberFilterSelections: {},
      filterNumberRanges: [],
      multiSelItems: [],
      filterTable: false,
      filterDialog: false,
      characterFilters: [],
      multiSelFilters: [],
      numberFilters: [],
      activeTable: [],
      headersWithPlots: [],
    };
  },

  methods: {
    applyAllFilters() {
      this.activeTable = this.getActiveTable();

      this.activeTable = this.activeTable.filter((row) => {
        const charactersOk = this.characterFilters.map((f) => {
          return f(row);
        });
        const multiSelOk = this.multiSelFilters.map((f) => {
          return f(row);
        });
        const numbersOk = this.numberFilters.map((f) => {
          return f(row);
        });
        return _.every(charactersOk.concat(multiSelOk).concat(numbersOk));
      });
    },

    changeNumberRange() {
      const activeFilters = _.pickBy(this.numberFilterSelections, (f) => {
        return !f.allSelected;
      });

      const newFunctions = _.map(activeFilters, (v, k) => {
        const f = function(row) {
          const n = Number(row[k].value);
          return n >= v.range[0] && n <= v.range[1] ? true : false;
        };
        return f;
      });

      this.numberFilters = newFunctions;
      this.applyAllFilters();
    },

    tableCellClass(header) {
      let cellClass = "pip-table-cell";
      if (header.value !== "Plots") cellClass += " v-data-table__divider";
      return cellClass;
    },

    openPlot: function(plotObj) {
      this.$store.commit("tabs/addTab", plotObj);
    },

    getActiveTable() {
      return this.filterTable ? this.filteredResTable : this.resTable;
    },

    filterText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value.value == "string" &&
        value.value
          .toString()
          .toLocaleLowerCase()
          .indexOf(search.toLocaleLowerCase()) !== -1
      );
    },

    columnSort(items, index, isDesc) {
      if (index.length) {
        items.sort((a, b) => {
          if (!isDesc[0]) {
            return a[index].value < b[index].value ? -1 : 1;
          } else {
            return a[index].value < b[index].value ? 1 : -1;
          }
        });
      }
      return items;
    },
  },

  watch: {
    filterTable: function(){
      this.applyAllFilters()
    },

    columnFilterTexts: {
      handler(data) {
        const newFunctions = _.map(data, (v, k) => {
          const f = function(row) {
            if (!row[k] || !row[k].value) return false;
            const lc = row[k].value.toLocaleLowerCase();
            return lc.includes(v.toLocaleLowerCase());
          };
          return f;
        });
        this.characterFilters = newFunctions;
        this.applyAllFilters();
      },
      deep: true,
    },

    multiFilterSelections: {
      handler(data) {
        const newFunctions = _.map(data, (v, k) => {
          const f = function(row) {
            const lc = row[k].value;
            return v == "All" ? true : lc == v;
          };
          return f;
        });

        this.multiSelFilters = newFunctions;
        this.applyAllFilters();
      },
      deep: true,
    },
  },
};
</script>

<style>
th[role="columnheader"] {
  white-space: nowrap;
}

.pip-table-cell {
  white-space: nowrap;
}
</style>
